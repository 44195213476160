<template>
  <div>
    <h4>Short Questions</h4>
    <span class="belowComment">
      For the following 6 questions, write as few or as many words as you feel
      necessary to get your ideas across. Long essays will not score you more
      points, and a 2-sentence response will not score you less points.
    </span>
    <!-- ans_to_extracurriculars -->
    <div class="mt-4">
      <span style="color: red">*</span>
      {{ $t("userProfile.teacher.ans_to_extracurriculars") }}
    </div>
    <el-form-item label-width="0px">
      <el-input
        style="margin: 12px 0px"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
        :placeholder="
          $t('userProfile.teacher.ans_to_extracurriculars_placeholder')
        "
        v-model="shortResponses.ans_to_extracurriculars"
      />
    </el-form-item>
    <!-- ans_to_teaching_years -->
    <div>
      <span style="color: red">*</span>
      {{ $t("userProfile.teacher.ans_to_teaching_years") }}
    </div>
    <el-form-item label-width="0px">
      <el-input
        style="margin: 12px 0px"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
        :placeholder="
          $t('userProfile.teacher.ans_to_teaching_years_placeholder')
        "
        v-model="shortResponses.ans_to_teaching_years"
      />
    </el-form-item>
    <!-- ans_to_student_numbers -->
    <div>
      <span style="color: red">*</span>
      {{ $t("userProfile.teacher.ans_to_student_numbers") }}
    </div>
    <el-form-item label-width="0px">
      <el-input
        style="margin: 12px 0px"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
        :placeholder="
          $t('userProfile.teacher.ans_to_student_numbers_placeholder')
        "
        v-model="shortResponses.ans_to_student_numbers"
      />
    </el-form-item>
    <!-- ans_to_capable_subjects -->
    <div>
      <span style="color: red">*</span>
      {{ $t("userProfile.teacher.ans_to_capable_subjects") }}
    </div>
    <el-form-item label-width="0px">
      <el-input
        style="margin: 12px 0px"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
        :placeholder="
          $t('userProfile.teacher.ans_to_capable_subjects_placeholder')
        "
        v-model="shortResponses.ans_to_capable_subjects"
      />
    </el-form-item>
    <!-- ans_to_relevant_work -->
    <div>
      <span style="color: red">*</span>
      {{ $t("userProfile.teacher.ans_to_relevant_work") }}
    </div>
    <el-form-item label-width="0px">
      <el-input
        style="margin: 12px 0px"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
        :placeholder="
          $t('userProfile.teacher.ans_to_relevant_work_placeholder')
        "
        v-model="shortResponses.ans_to_relevant_work"
      />
    </el-form-item>
    <!-- ans_to_students_perform -->
    <div>
      <span style="color: red">*</span>
      {{ $t("userProfile.teacher.ans_to_students_perform") }}
    </div>
    <el-form-item label-width="0px">
      <el-input
        style="margin: 12px 0px"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
        :placeholder="
          $t('userProfile.teacher.ans_to_students_perform_placeholder')
        "
        v-model="shortResponses.ans_to_students_perform"
      />
    </el-form-item>
    <hr />
    <h4>Documents</h4>
    <span class="belowComment" style="display: block; margin-bottom: 8px">
      Please include the following documents in pdf, doc or docx format:
    </span>
    <el-form-item
      label-width="0px"
      prop="high_school_resume_url"
      :rules="commonRules.input"
    >
      <div style="font-size:1rem">
        <span style="color: red">*</span>
        1. High school resume
      </div>
      <div
        v-loading="high_school_resume_urlLoading"
        element-loading-text="Uploading"
      >
        <div style="margin: 4px 0px;">
          <template v-if="shortResponses.high_school_resume_url">
            <a :href="shortResponses.high_school_resume_url" target="_blank">
              查看檔案內容 <i class="fas fa-eye action-icon" />
            </a>
            <el-button
              v-if="!displayUploadHighSchoolResume"
              @click="displayUploadHighSchoolResume = true"
              size="mini"
              type="primary"
            >
              編輯上傳檔案
            </el-button>
            <el-button
              v-if="displayUploadHighSchoolResume"
              @click="displayUploadHighSchoolResume = false"
              size="mini"
              type="danger"
            >
              取消編輯
            </el-button>
          </template>
          <span v-else>請上傳檔案</span>
        </div>
        <el-upload
          v-if="
            !shortResponses.high_school_resume_url ||
              displayUploadHighSchoolResume
          "
          drag
          :action="postUrl"
          :headers="token"
          :data="{ type: 'apply' }"
          name="document"
          :show-file-list="false"
          :on-success="
            res => handleAvatarSuccess('high_school_resume_url', res)
          "
          :before-upload="
            file => beforeAvatarUpload('high_school_resume_url', file)
          "
        >
          <div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              將文件拖到此處，或<em>點擊上傳</em>
            </div>
          </div>
          <small slot="tip" class="belowComment">
            You can submit your high school activities/awards list if you don't
            have a "resume."
          </small>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label-width="0px" prop="college_resume_url">
      <div style="font-size:1rem">
        2. College resume
      </div>
      <div
        v-loading="college_resume_urlLoading"
        element-loading-text="Uploading"
      >
        <div style="margin: 4px 0px;">
          <template v-if="shortResponses.college_resume_url">
            <a :href="shortResponses.college_resume_url" target="_blank">
              查看檔案內容 <i class="fas fa-eye action-icon" />
            </a>
            <el-button
              v-if="!displayUploadColleageResume"
              @click="displayUploadColleageResume = true"
              size="mini"
              type="primary"
            >
              編輯上傳檔案
            </el-button>
            <el-button
              v-if="displayUploadColleageResume"
              @click="displayUploadColleageResume = false"
              size="mini"
              type="danger"
            >
              取消編輯
            </el-button>
          </template>
          <span v-else>請上傳檔案</span>
        </div>
        <el-upload
          v-if="
            !shortResponses.college_resume_url || displayUploadColleageResume
          "
          drag
          :action="postUrl"
          :headers="token"
          :data="{ type: 'apply' }"
          name="document"
          :show-file-list="false"
          :on-success="res => handleAvatarSuccess('college_resume_url', res)"
          :before-upload="
            file => beforeAvatarUpload('college_resume_url', file)
          "
        >
          <div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              將文件拖到此處，或<em>點擊上傳</em>
            </div>
          </div>
          <small slot="tip" class="belowComment">
            Not necessary if you're a freshman whose college resume looks very
            similar to high school's.
          </small>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label-width="0px" prop="essay_url" :rules="commonRules.input">
      <div style="font-size:1rem">
        <span style="color: red">*</span>
        3. College application essay
      </div>
      <div v-loading="essay_urlLoading" element-loading-text="Uploading">
        <div style="margin: 4px 0px;">
          <template v-if="shortResponses.essay_url">
            <a :href="shortResponses.essay_url" target="_blank">
              查看檔案內容 <i class="fas fa-eye action-icon" />
            </a>
            <el-button
              v-if="!displayUploadEssayUrl"
              @click="displayUploadEssayUrl = true"
              size="mini"
              type="primary"
            >
              編輯上傳檔案
            </el-button>
            <el-button
              v-if="displayUploadEssayUrl"
              @click="displayUploadEssayUrl = false"
              size="mini"
              type="danger"
            >
              取消編輯
            </el-button>
          </template>
          <span v-else>請上傳檔案</span>
        </div>
        <el-upload
          v-if="!shortResponses.essay_url || displayUploadEssayUrl"
          drag
          :action="postUrl"
          :headers="token"
          :data="{ type: 'apply' }"
          name="document"
          :show-file-list="false"
          :on-success="res => handleAvatarSuccess('essay_url', res)"
          :before-upload="file => beforeAvatarUpload('essay_url', file)"
        >
          <div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              將文件拖到此處，或<em>點擊上傳</em>
            </div>
          </div>
          <small slot="tip" class="belowComment">
            You can submit your <b>UC/Common App essay</b> or the
            <b>"why do you want to attend our school"</b> essay. If you no
            longer have it, please submit a 300-500 words creative essay you've
            written for class or fun.
          </small>
        </el-upload>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import JwtService from "@/common/jwt.service";
import formMixin from "@/mixins/form";

export default {
  props: {
    shortResponses: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      college_resume_urlLoading: false,
      essay_urlLoading: false,
      high_school_resume_urlLoading: false,
      displayUploadHighSchoolResume: false,
      displayUploadColleageResume: false,
      displayUploadEssayUrl: false
    };
  },
  mixins: [formMixin],
  computed: {
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "teacher-application-document";
    }
  },
  methods: {
    handleAvatarSuccess(type, res) {
      this.$emit("handleShortResponses", { [type]: res.document.url });
      this[`${type}Loading`] = false;
      this.displayUploadContract = false;
    },
    beforeAvatarUpload(type, file) {
      if (
        file.type !== "application/pdf" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file.type !== "application/msword"
      ) {
        this.$message.error(
          "上傳檔案類型只能是 pdf、doc 或 docx！請重新選擇檔案！"
        );
        return false;
      }

      this[`${type}Loading`] = true;
      return true;
    }
  }
};
</script>

<style scoped>
.inputNumber {
  width: 90%;
}

.belowComment {
  display: block;
  color: #737373;
  line-height: 1.5rem;
}

::v-deep .el-form-item__error {
  margin-top: -8px;
}
</style>
