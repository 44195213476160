<template>
  <div>
    <h4>Personal</h4>
    <!-- <el-form-item required label="Name" prop="name" :rules="commonRules.input">
      <el-input
        :value="basicInfo.name"
        @input="value => $emit('handleBasicInfo', { name: value })"
      />
    </el-form-item> -->
    <el-form-item
      required
      :label="$t('registerDetail.firstName')"
      prop="first_name"
      :rules="commonRules.input"
    >
      <el-input
        :value="basicInfo.first_name"
        :placeholder="$t('registerDetail.firstNamePlaceholder')"
        @input="value => $emit('handleBasicInfo', { first_name: value })"
      />
    </el-form-item>
    <el-form-item
      required
      :label="$t('registerDetail.lastName')"
      prop="last_name"
      :rules="commonRules.input"
    >
      <el-input
        :value="basicInfo.last_name"
        :placeholder="$t('registerDetail.lastNamePlaceholder')"
        @input="value => $emit('handleBasicInfo', { last_name: value })"
      />
    </el-form-item>
    <!-- <el-form-item required label="Name" prop="name" :rules="commonRules.input">
      <el-input
        :value="basicInfo.name"
        placeholder="Name"
        @input="value => $emit('handleBasicInfo', { name: value })"
      />
    </el-form-item> -->
    <el-form-item
      required
      :label="$t('registerDetail.email')"
      prop="email"
      :rules="[commonRules.input, commonRules.email]"
    >
      <el-input
        :value="basicInfo.email"
        :placeholder="$t('registerDetail.email')"
        @input="value => $emit('handleBasicInfo', { email: value })"
      />
    </el-form-item>
    <el-form-item
      required
      :label="$t('teacherDetail.phone')"
      prop="phone"
      :rules="[commonRules.input, commonRules.phone]"
    >
      <el-input
        :value="basicInfo.phone"
        :placeholder="$t('teacherDetail.phone')"
        @input="value => $emit('handleBasicInfo', { phone: value })"
      />
    </el-form-item>
    <hr />
    <h4>Education</h4>
    <el-form-item
      required
      :label="$t('teacherDetail.college')"
      prop="school"
      :rules="commonRules.input"
    >
      <el-input
        :value="basicInfo.school"
        :placeholder="$t('teacherDetail.college')"
        @input="value => $emit('handleBasicInfo', { school: value })"
      />
    </el-form-item>
    <el-form-item
      required
      :label="$t('teacherDetail.gradeLevel')"
      prop="graduation_year"
      :rules="commonRules.input"
    >
      <el-select
        :value="basicInfo.graduation_year"
        :placeholder="$t('teacherDetail.gradeLevel')"
        style="width:100%"
        @input="value => $emit('handleBasicInfo', { graduation_year: value })"
      >
        <div
          slot="prefix"
          class="input-icon icon-school"
          :style="
            colorChangeGrade
              ? 'background-color: #42a16a;'
              : 'background-color: #757575;'
          "
        ></div>
        <el-option
          v-for="(item, index) in graduateSelection"
          :key="index"
          :label="$t(item.label, { year: item.param })"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item
      required
      label="Graduation year"
      prop="graduation_year"
      :rules="commonRules.input"
    >
      <el-input
        :value="basicInfo.graduation_year"
        @input="value => $emit('handleBasicInfo', { graduation_year: value })"
      />
    </el-form-item> -->
    <el-form-item
      required
      :label="$t('teacherDetail.major')"
      prop="major"
      :rules="commonRules.input"
    >
      <el-input
        :placeholder="$t('teacherDetail.major')"
        :value="basicInfo.major"
        @input="value => $emit('handleBasicInfo', { major: value })"
      />
    </el-form-item>
    <el-form-item
      required
      label="Cumulative GPA"
      prop="gpa_score"
      :rules="[commonRules.input, rules.gpa]"
    >
      <el-input
        :value="basicInfo.gpa_score"
        @input="value => $emit('handleBasicInfo', { gpa_score: value })"
      />
    </el-form-item>
  </div>
</template>

<script>
import gradeLevelMixin from "@/mixins/gradeLevel";
import formMixin from "@/mixins/form";

export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [formMixin, gradeLevelMixin],
  data() {
    return {
      graduateSelection: []
    };
  },
  computed: {
    rules() {
      const validGPA = (rule, value, callback) => {
        if (
          !Number.isNaN(Number(this.basicInfo.gpa_score)) &&
          Number(this.basicInfo.gpa_score) <= 4
        ) {
          callback();
          return;
        }
        callback(new Error());
      };

      return {
        gpa: {
          validator: validGPA,
          message: "Please enter a value less than or equal to 4.",
          trigger: "blur"
        }
      };
    }
  },
  created() {
    // clean up the event objec
    this.graduateSelection = this.createTeacherGraduateSelection();
  }
};
</script>

<style scoped>
::v-deep .el-input--prefix .el-input__inner {
  padding: 0 15px;
}
::v-deep .el-form-item__label {
  margin-bottom: 0;
}
::v-deep .el-form-item {
  margin-bottom: 2rem;
}
</style>
