<template>
  <div>
    <h4>Test scores</h4>
    <div class="mt-4">
      {{ $t("teacherDetail.askTest") }}
    </div>
    <el-form-item>
      <el-select
        v-model="testsList"
        multiple
        :placeholder="$t('teacherDetail.pleaseSelect')"
        style="width:100%;"
        class="mt-2"
      >
        <el-option
          v-for="(item, index) in tests"
          :key="index"
          :label="item.name.toUpperCase()"
          :value="item.value"
        ></el-option>
      </el-select>
      <!-- Show the tests -->
      <el-card
        v-if="testsList && testsList.length > 0"
        class="mt-4"
        shadow="never"
      >
        <el-row v-for="(test, index) in testsList" :key="index">
          <el-col v-if="test">
            <Tests
              :test="findTest(test)"
              @dataFromChild="updateTest"
              :isTeacher="true"
            />
          </el-col>
        </el-row>
      </el-card>
    </el-form-item>
    <!-- <el-form-item :rules="[rules.sat, rules.satWriting]" prop="sat">
      <template slot="label">
        Sat
      </template>
      <div style="display: flex">
        <el-input
          class="satScoreInput"
          :value="testScores.sat.sat_reading_score"
          placeholder="Reading"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                sat: { ...testScores.sat, sat_reading_score: value }
              })
          "
        />
        <el-input
          class="satScoreInput"
          :value="testScores.sat.sat_math_score"
          placeholder="Math"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                sat: { ...testScores.sat, sat_math_score: value }
              })
          "
        />
        <el-input
          class="satScoreInput"
          :value="testScores.sat.sat_writing_score"
          placeholder="Writing"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                sat: { ...testScores.sat, sat_writing_score: value }
              })
          "
        />
        <div class="satScoreInput">Total: {{ testScores.sat_score }}</div>
      </div>
    </el-form-item>
    <el-form-item :rules="rules.act" prop="act">
      <template slot="label">
        Act
      </template>
      <div style="display: flex; margin-bottom: -20px">
        <el-input
          class="actScoreInput"
          :value="testScores.act.act_english_score"
          placeholder="English"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                act: { ...testScores.act, act_english_score: value }
              })
          "
        />
        <el-input
          class="actScoreInput"
          :value="testScores.act.act_math_score"
          placeholder="Math"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                act: { ...testScores.act, act_math_score: value }
              })
          "
        />
        <el-input
          class="actScoreInput"
          :value="testScores.act.act_reading_score"
          placeholder="Reading"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                act: { ...testScores.act, act_reading_score: value }
              })
          "
        />
        <el-input
          class="actScoreInput"
          :value="testScores.act.act_science_score"
          placeholder="Science"
          controls-position="right"
          @input="
            value =>
              $emit('handleTestScores', {
                act: { ...testScores.act, act_science_score: value }
              })
          "
        />
        <div class="actScoreInput">Total: {{ testScores.act_score }}</div>
      </div>
    </el-form-item>
    <el-form-item label="AP" prop="ap_scores">
      <el-input
        placeholder="AP"
        :value="testScores.ap_scores"
        @input="value => $emit('handleTestScores', { ap_scores: value })"
      />
      <span class="belowComment"
        >Eg. Biology (5), Calculus BC (5), Physics (4).</span
      >
    </el-form-item>
    <el-form-item label="IB" prop="ib_scores">
      <el-input
        placeholder="IB"
        :value="testScores.ib_scores"
        @input="value => $emit('handleTestScores', { ib_scores: value })"
      />
      <span class="belowComment">Eg. Biology SL (7), English A1 HL (5).</span>
    </el-form-item>
    <el-form-item label="TOEFL" prop="toefl_scores">
      <el-input
        placeholder="TOEFL"
        :value="testScores.toefl_scores"
        @input="value => $emit('handleTestScores', { toefl_scores: value })"
      />
      <span class="belowComment"
        >Eg. Reading (25), Writing (25), Listening (28), Speaking (28).</span
      >
    </el-form-item>
    <el-form-item label="Other" prop="other_scores">
      <el-input
        placeholder="Other tests"
        :value="testScores.other_scores"
        @input="value => $emit('handleTestScores', { other_scores: value })"
      />
      <span class="belowComment"
        >Eg. GRE, GMAT, LSAT, etc. Please include score breakdown.</span
      >
    </el-form-item> -->
    <div>
      What other school(s) also accepted you?
    </div>
    <el-form-item prop="other_schools_accepted">
      <el-input
        class="mt-2 mb-4"
        :value="testScores.other_schools_accepted"
        @input="
          value => $emit('handleTestScores', { other_schools_accepted: value })
        "
      />
    </el-form-item>
    <hr />
    <h4>A little more about you</h4>
    <div class="mt-4">
      <span style="color: red">*</span>
      1. What language(s) other than English do you know? Please very briefly
      describe your comfort level.
    </div>
    <el-form-item
      :rules="commonRules.input"
      label-width="0px"
      prop="other_languages"
    >
      <el-input
        style="margin: 12px 0px"
        :value="testScores.other_languages"
        @input="value => $emit('handleTestScores', { other_languages: value })"
      />
    </el-form-item>
    <div>
      <span style="color: red">*</span>
      2. What position(s) are you applying for?
    </div>
    <el-form-item
      :rules="commonRules.checkbox"
      label-width="0px"
      prop="apply_for_positions"
    >
      <el-checkbox-group
        class="ml-4 mt-2"
        :value="testScores.apply_for_positions"
        @input="
          value => $emit('handleTestScores', { apply_for_positions: value })
        "
      >
        <div
          v-for="(checkItem, index) in checkList"
          :key="index"
          style="height:30px;line-height:30px;"
        >
          <el-checkbox :label="index">
            {{ checkItem.name }}
            <!-- <el-tooltip
              content="Summer Teaching Fellows teach our summer program in Taiwan (Taipei, Taichung, or Kaohsiung) or China (Shanghai). It starts in mid-June or early-July and lasts for 8 weeks, including 2 weeks of training and 4 weeks of teaching."
              placement="top"
              popper-class="tooltipColor"
            >
              <span>[?]</span>
            </el-tooltip> -->
          </el-checkbox>
        </div>
        <!-- <div>
          <el-checkbox disabled label="1">
            <s>
              Christmas/J-Term Teaching Fellow (Taipei, Taiwan)
              <el-tooltip
                content="Christmas/J-Term Teaching Fellows teach our condensed SAT program in Taipei, Taiwan. It starts in mid- to late-December and lasts for 3 weeks (excluding pre-program online training), including 2 weeks of intensive classes and 1 week of curriculum development or tutoring."
                placement="top"
                popper-class="tooltipColor"
              >
                <s>[?]</s>
              </el-tooltip>
            </s>
          </el-checkbox>
        </div>
        <div class="lineHeight">
          <el-checkbox label="2">
            Summer Teaching Fellow (Taiwan or China)
            <el-tooltip
              content="Summer Teaching Fellows teach our summer program in Taiwan (Taipei, Taichung, or Kaohsiung) or China (Shanghai). It starts in mid-June or early-July and lasts for 8 weeks, including 2 weeks of training and 4 weeks of teaching."
              placement="top"
              popper-class="tooltipColor"
            >
              <span>[?]</span>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div class="lineHeight">
          <el-checkbox label="3">
            Summer Teaching Assistant (Taiwan or China)
            <el-tooltip
              content="Summer Teaching Assistants assist Teaching Fellows during our summer program in Taiwan (Taipei, Taichung, or Kaohsiung) or China (Shanghai). It starts in early- or mid-June and lasts for 8 weeks, including 2 weeks of training and 4 weeks of teaching."
              placement="top"
              popper-class="tooltipColor"
            >
              <span>[?]</span>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div class="lineHeight">
          <el-checkbox label="4">
            Part-time Teaching Fellow (online)
            <el-tooltip
              content="Online Teaching Fellows focus on teaching the SAT or other school subjects, general counselling, and mentorship. The time commitment per student is usually 2-4 hours a week, but it's case by case."
              placement="top"
              popper-class="tooltipColor"
            >
              <span>[?]</span>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div class="lineHeight">
          <el-checkbox label="5">
            Full-time/Part-time Teaching Fellow (Shanghai or Taiwan)
            <el-tooltip
              content="Full-time/Part-time Teaching Fellows focus on teaching, education research, and curriculum development. Full-time/Part-time Teaching Fellows must relocate to Taiwan or China."
              placement="top"
              popper-class="tooltipColor"
            >
              <span>[?]</span>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div class="lineHeight">
          <el-checkbox label="6">
            Full-time Branch Manager in Training (Shanghai or Taiwan)
            <el-tooltip
              content="Full time Branch Manager focus on teacher management, business development, research, curriculum development, and teaching, and help with Ivy-Way's daily operations. Minimum 1 year commitment. Full-time Branch Manager in Training must relocate to Taiwan or China."
              placement="top"
              popper-class="tooltipColor"
            >
              <span>[?]</span>
            </el-tooltip>
          </el-checkbox>
        </div> -->
      </el-checkbox-group>
    </el-form-item>
    <div>
      <span style="color: red">*</span>
      3. How did you hear about us?
    </div>
    <el-form-item :rules="commonRules.input" label-width="0px" prop="know_us">
      <el-input
        style="margin: 12px 0px"
        :value="testScores.know_us"
        @input="value => $emit('handleTestScores', { know_us: value })"
      />
    </el-form-item>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";
import testHelper from "@/mixins/testHelper";
import Tests from "@/components/roleBinding/components/Tests";

export default {
  props: {
    testScores: {
      type: Object,
      default: () => ({})
    },
    tests: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Tests
  },
  data() {
    return {
      checkList: [
        {
          name: "Part-time Instructor (Online)"
        },
        {
          name: "Part-time Instructor (Taiwan)"
        },
        {
          name: "Summer Instructor (Taiwan)"
        },
        {
          name: "Full-time Instructor (Taiwan)"
        },
        {
          name: "Full-time Branch Manager (Taiwan)"
        }
      ],
      testsList: []
    };
  },
  mixins: [testHelper, formMixin],
  computed: {
    rules() {
      const validSatWriting = (rule, value, callback) => {
        if (this.testScores.sat.sat_writing_score <= 24) {
          callback();
          return;
        }
        callback(new Error());
      };

      const validSat = (rule, value, callback) => {
        if (
          this.testScores.sat.sat_reading_score <= 800 &&
          this.testScores.sat.sat_math_score <= 800
        ) {
          callback();
          return;
        }
        callback(new Error());
      };

      const validAct = (rule, value, callback) => {
        if (
          this.testScores.act.act_english_score <= 36 &&
          this.testScores.act.act_math_score <= 36 &&
          this.testScores.act.act_reading_score <= 36 &&
          this.testScores.act.act_science_score <= 36
        ) {
          callback();
          return;
        }
        callback(new Error());
      };

      return {
        sat: {
          validator: validSat,
          message: "Please enter a value less than or equal to 800 .",
          trigger: "blur"
        },
        satWriting: {
          validator: validSatWriting,
          message:
            "Please enter a value less than or equal to 24 in the Writing.",
          trigger: "blur"
        },
        act: {
          validator: validAct,
          message: "Please enter a value less than or equal to 36.",
          trigger: "blur"
        }
      };
    }
  }
};
</script>

<style scoped>
.satScoreInput {
  width: 20%;
  margin-right: 20px;
}

.actScoreInput {
  width: 15%;
  margin-right: 20px;
}

.belowComment {
  color: #737373;
}

.lineHeight {
  margin-top: -16px;
}
</style>
