<template>
  <div class="box">
    <div class="apply">
      <h2 class="applyTitle">JOIN US</h2>
      <hr class="separate-line" />
      <el-steps :active="currentStep" align-center>
        <el-step
          :title="`Part ${i}`"
          v-for="i in 3"
          :key="i"
          :description="`${applyStepName[i]}`"
        ></el-step>
        <!-- <el-step title="步骤 2" description="这是一段很长很长很长的描述性文字"></el-step>
        <el-step title="步骤 3" description="这段就没那么长了"></el-step> -->
      </el-steps>
      <!-- <h3>{{ `Part ${currentStep} of 3: ${applyStepName[currentStep]}` }}</h3> -->
      <hr />
      <el-form :model="form.basicInfo" label-width="200px" ref="basicInfo">
        <Step1
          v-if="currentStep === 1"
          :basicInfo="form.basicInfo"
          @handleBasicInfo="handleBasicInfo"
        />
      </el-form>
      <el-form :model="form.testScores" label-position="left" label-width="0" ref="testScores">
        <Step2
          v-if="currentStep === 2"
          :tests="form.tests"
          :testScores="form.testScores"
          @handleTestScores="handleTestScores"
        />
      </el-form>
      <el-form
        :model="form.shortResponses"
        label-width="230px"
        ref="shortResponses"
      >
        <Step3
          v-if="currentStep === 3"
          :shortResponses="form.shortResponses"
          @handleShortResponses="handleShortResponses"
        />
      </el-form>
      <hr />
      <div class="footer">
        <div style="text-align: left; width: 50%">
          <el-button
            v-if="currentStep !== 1"
            type="primary"
            class="handleStepBtn"
            @click="handleStep(currentStep - 1)"
          >
            <i class="fas fa-arrow-left" /> Back
          </el-button>
        </div>
        <div style="text-align: right; width: 50%">
          <el-button
            v-if="currentStep !== 3"
            key="next"
            type="primary"
            class="handleStepBtn"
            @click="handleStep(currentStep + 1)"
          >
            Next <i class="fas fa-arrow-right" />
          </el-button>
          <div v-else>
            <el-button
              key="submit"
              class="handleStepBtn mb-4"
              type="primary"
              @click="submitApply"
            >
              Submit
            </el-button>
            <br />
            <span style="font-size: 12px; color: #333;">
              By submitting the application, you verify that your information is
              accurate<br />
              and you agree to Ivy-Way's
              <a href="terms" target="_blank">Terms and Condition</a>.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import formMixin from "@/mixins/form";
import applyApi from "@/apis/apply.js";
import testHelper from "@/mixins/testHelper";

export default {
  metaInfo() {
    return {
      title: "Join Us - Ivy-Way Academy"
    };
  },
  components: { Step1, Step2, Step3 },
  mixins: [testHelper, formMixin],
  data() {
    return {
      currentStep: 1,
      form: {
        basicInfo: {
          first_name: "",
          last_name: "",
          name: "",
          email: "",
          phone: "",
          school: "",
          graduation_year: null,
          major: "",
          gpa_score: null
        },
        testScores: {
          // sat: {
          //   sat_reading_score: null,
          //   sat_math_score: null,
          //   sat_writing_score: null
          // },
          // sat_score: 0,
          // act: {
          //   act_english_score: null,
          //   act_math_score: null,
          //   act_reading_score: null,
          //   act_science_score: null
          // },
          // act_score: 0,
          // ap_scores: "",
          // ib_scores: "",
          // toefl_scores: "",
          // other_scores: "",
          other_schools_accepted: "",
          other_languages: "",
          apply_for_positions: [],
          know_us: ""
        },
        tests: this.getCurrentTestArray([
          "SAT_teacher",
          "ACT",
          "AP",
          "IB",
          "TOEFL",
          "IELTS"
        ]),
        shortResponses: {
          ans_to_extracurriculars: "",
          ans_to_teaching_years: "",
          ans_to_student_numbers: "",
          ans_to_capable_subjects: "",
          ans_to_relevant_work: "",
          ans_to_students_perform: "",
          high_school_resume_url: "",
          college_resume_url: "",
          essay_url: ""
        }
      }
    };
  },
  computed: {
    applyStepName() {
      return {
        1: "Basic info",
        2: "Background",
        3: "Short responses"
      };
    }
  },
  watch: {},
  created() {},
  methods: {
    async handleStep(step) {
      if (this.currentStep === 1) {
        const valid = await this.validateForm(this.$refs["basicInfo"]);
        if (!valid) return;
      } else if (this.currentStep === 2) {
        const valid = await this.validateForm(this.$refs["testScores"]);
        if (!valid) return;
      }

      this.currentStep = step;
    },
    async submitApply() {
      const valid = await this.validateForm(this.$refs["shortResponses"]);
      if (!valid) return;

      const form = {
        ...this.form.basicInfo,
        ...this.form.testScores,
        // ...this.form.testScores.sat,
        // ...this.form.testScores.act,
        ...this.form.shortResponses,
        tests: this.form.tests
      };
      delete form.sat;
      delete form.act;

      try {
        await applyApi.teacherApply(form);
        this.$router.push({ name: "ApplyThanks" });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    handleBasicInfo(value) {
      this.form = {
        ...this.form,
        basicInfo: {
          ...this.form.basicInfo,
          ...value
        }
      };
    },
    handleTestScores(value) {
      this.form = {
        ...this.form,
        testScores: {
          ...this.form.testScores,
          ...value
        }
      };
      // const {
      //   sat_reading_score,
      //   sat_math_score,
      //   sat_writing_score
      // } = this.form.testScores.sat;
      // this.form.testScores.sat_score =
      //   Number(sat_reading_score) + Number(sat_math_score);

      // const {
      //   act_english_score,
      //   act_math_score,
      //   act_reading_score,
      //   act_science_score
      // } = this.form.testScores.act;
      // this.form.testScores.act_score = Math.round(
      //   (Number(act_english_score) +
      //     Number(act_math_score) +
      //     Number(act_reading_score) +
      //     Number(act_science_score)) /
      //     4
      // );
    },
    handleShortResponses(value) {
      this.form = {
        ...this.form,
        shortResponses: {
          ...this.form.shortResponses,
          ...value
        }
      };
    }
  }
};
</script>

<style scoped>
.box {
  max-width: 1080px;
  margin: 2rem auto;
}
.apply {
  font-family: "Helvetica Neue", sans-serif;
  padding: 20px 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.applyTitle {
  margin: 20px 0px;
}

.footer {
  margin: 20px 0px;
  display: flex;
}

.handleStepBtn {
  width: 200px;
}
</style>
