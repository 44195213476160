import ApiService from "@/common/api.service";

export default {
  teacherApply(data) {
    return ApiService.post("teacher-apply", data);
  },
  uploadTeacherApplyDocument(data) {
    return ApiService.post("teacher-application-document", data);
  }
};
